<ng-container *transloco="let t">
    <!-- Button -->
    <button mat-icon-button [matMenuTriggerFor]="userActions">
        <span class="relative">
            <mat-icon [svgIcon]="'heroicons_outline:user-circle'"></mat-icon>
        </span>
    </button>

    <mat-menu [xPosition]="'before'" #userActions="matMenu">
        <button mat-menu-item>
            <span class="flex flex-col leading-none">
                <span>{{ t("signed-in-as") }}</span>
                <span class="mt-1.5 text-md font-medium">{{ user.email }}</span>
            </span>
        </button>
        <mat-divider class="my-2"></mat-divider>
        <button mat-menu-item (click)="signOut()">
            <mat-icon
                [svgIcon]="'heroicons_outline:arrow-right-on-rectangle'"
            ></mat-icon>
            <span>{{ t("sign-out") }}</span>
        </button>
    </mat-menu>
</ng-container>
