import { inject } from '@angular/core';
import { CanActivateChildFn, CanActivateFn } from '@angular/router';
import { OAuthService } from 'angular-oauth2-oidc';
import { UserService } from 'app/core/user/user.service';
import { filter, forkJoin, map, of, take } from 'rxjs';

export const AuthGuard: CanActivateFn | CanActivateChildFn = (route, state) => {
    const hasValidAccessToken = inject(OAuthService).hasValidAccessToken();
    const user$ = inject(UserService).user$.pipe(
        filter((user) => !!user),
        take(1)
    );
    // TODO: check why hasValidAccessToken is returning true even if token is expired
    // check if token is expired by hand
    const tokeExpired =
        inject(OAuthService).getAccessTokenExpiration() < new Date().getTime();
    return forkJoin([of(hasValidAccessToken && !tokeExpired), user$]).pipe(
        map(([hasToken, user]) => hasToken && !!user)
    );
};
